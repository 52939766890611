<script setup>
import { storeCollapseNav } from "@vueda/stores/storeCollapseNav.js";
import { storeDarkMode } from "@vueda/stores/storeDarkMode.js";
import { storeUser } from "@vueda/stores/storeUser.js";
import { useVersion } from "@vueda/use/useVersion.js";
import { breakpointsVueda } from "@vueda/utils/breakpoints.js";
import { getAppModelViewDotName } from "@vueda/utils/crudSupport.js";
import { fetchHelper } from "@vueda/utils/fetchSupport.js";
import { useBreakpoints } from "@vueuse/core";
import Divider from "primevue/divider";
import { computed, onMounted, ref, toRef } from "vue";
import { useRouter } from "vue-router";

import ButtonIcon from "@/components/ButtonIcon.vue";
import LinkIcon from "@/components/LinkIcon.vue";
import { LEGACY_HOSTNAME } from "@/utils/legacyHostname.js";

const version = useVersion();
const userStore = storeUser();
const loggedInUser = toRef(userStore, "loggedInUser");
const collapseNavStore = storeCollapseNav();
const darkModeStore = storeDarkMode();
const router = useRouter();

const newSiteApps = ["tim"]; // List of apps fully migrated to the new site
const newSiteModels = ["tim.timesheet"]; // List of models migrated to the new site
const collapsedAppLabel = ["Settings"]; // List of apps that should not show models

const navigationItems = ref([
    {
        label: "Welcome",
        key: "welcome",
        isNewSite: true,
        verb: "goHome",
        to: { name: "welcome" },
    },
]);

// the old site has some really long tab names.
// to make the collapse animation better, we want to fit on one line
const labelsMap = {
    "Field compaction test results": "Field compactions",
    "Collection notifications": "Collections",
    "Received payments": "Payments",
    "Invoiceable projects": "Invoiceables",
    "Density result tests": "Density results",
    "Vacation breakpoints": "Vac. breakpoints",
    "Lab tests and dispatch": "Lab tests & dispatch",
};

const mapLabel = (label) => {
    return labelsMap[label] || label;
};

const upperOnlyTitleCase = (str) => {
    // don't botch 'HR' or 'A/R', but fix 'Lab tests and dispatch'
    return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const isCollapsed = toRef(collapseNavStore, "isCollapsed");
const breakpoints = useBreakpoints(breakpointsVueda);
const greaterOrEqualLg = breakpoints.greaterOrEqual("lg");
const showLabels = computed(() => (greaterOrEqualLg.value ? !isCollapsed.value : true));
const showTooltip = computed(() => (greaterOrEqualLg.value ? isCollapsed.value : false));
const collapsedHamburger = computed(() => (!greaterOrEqualLg.value ? isCollapsed.value : false));

// negation of computeds is annoying inline
const isExpanded = computed(() => !isCollapsed.value);

onMounted(async () => {
    collapseNavStore.init();
    const results = await fetchHelper(
        `${LEGACY_HOSTNAME}/api/navigation/`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        },
        "Failed to fetch navigation items.",
    );

    for (const appItem of results) {
        const isNewSiteApp = newSiteApps.includes(appItem.name);

        const appNavItem = {
            label: upperOnlyTitleCase(mapLabel(appItem.label)),
            app: appItem.name,
            isNewSite: isNewSiteApp,
            url: appItem.url,
            key: appItem.name || appItem.label,
            items: [],
        };

        if (appItem.label === "Settings") {
            appNavItem.verb = "settings";
        }

        if (appItem.models.length === 1) {
            delete appNavItem["items"];
            const model = appItem.models[0];
            appNavItem.model = model.name.split(".")[1]; // Extract model name
            appNavItem.view = "list"; // Default view
            appNavItem.url = model.url;
            appNavItem.key = getAppModelViewDotName(model);
        } else if (collapsedAppLabel.includes(appItem.label)) {
            // too many sub items, just show the app as an item
            delete appNavItem["items"];
        } else if (appItem.models && appItem.models.length > 0) {
            for (const modelItem of appItem.models) {
                const isNewSiteModel = newSiteModels.includes(modelItem.name);
                let calcApp;
                if (!modelItem.app) {
                    // this is an artifcat of a top level item not
                    //  strictly associated with a particular app.
                    calcApp = modelItem.name.split(".")[0];
                } else {
                    calcApp = modelItem.app;
                }

                const navItem = {
                    label: mapLabel(modelItem.label),
                    app: calcApp,
                    model: modelItem.name.split(".")[1], // Extract model name
                    view: "list", // Default view
                    isNewSite: isNewSiteModel,
                    url: modelItem.url,
                    key: getAppModelViewDotName(modelItem),
                };
                if (isNewSiteModel) {
                    // stay on the new site
                    delete navItem["url"];
                }
                appNavItem.items.push(navItem);
            }
        }

        navigationItems.value.push(appNavItem);
    }
    // organize solo items into an app with a flag to omit the label
    const soloItems = navigationItems.value.filter((item) => !item.items);
    const soloApp = {
        label: null,
        key: "solo",
        items: soloItems,
    };
    navigationItems.value = navigationItems.value.filter((item) => item.items);
    navigationItems.value.unshift(soloApp);

    // setup disclosure status for categories
    for (const item of navigationItems.value) {
        if (item.items) {
            menuCategoryDisclosureStatus.value[item.key] = false;
        }
    }
});
// when the site is under lg, and we are using a top bar.
// I want to have categories collapsed by default.
// and add a button to the divider to toggle the category.
const menuCategoryDisclosureStatus = ref({});
const toggleDisclosure = (key) => {
    // accordion mode, there can only be one open at a time
    for (const [k, v] of Object.entries(menuCategoryDisclosureStatus.value)) {
        if (k === key) {
            menuCategoryDisclosureStatus.value[k] = !v;
        } else {
            menuCategoryDisclosureStatus.value[k] = false;
        }
    }
};
const navBackgroundClass = "bg-surface-0 dark:bg-surface-950";
</script>

<template>
    <header
        class="shrink-0 flex flex-col print:hidden z-40 transition-all ease-in-out"
        :class="{ 'lg:w-64': isExpanded, 'lg:w-20': isCollapsed }"
    >
        <div
            class="flex px-2 lg:hidden sticky border-primary py-2 gap-2"
            :class="{
                [navBackgroundClass]: true,
                'border-b-4': isCollapsed,
            }"
        >
            <div>
                <button-icon
                    v-tooltip="isCollapsed ? 'Expand' : 'Collapse'"
                    class="grow whitespace-nowrap"
                    :verb="isCollapsed ? 'collapseRight' : 'collapseLeft'"
                    size="small"
                    @click="collapseNavStore.toggle"
                />
            </div>
            <router-link class="block !no-underline grow" :to="{ name: 'welcome' }">
                <h1 class="text-center grow overflow-hidden leading-loose tracking-widest whitespace-nowrap lg:hidden">
                    <span class="text-sm">
                        <img
                            src="@/assets/shelby-text.svg"
                            alt="Shelby"
                            class="h-[0.7rem] inline align-baseline"
                        />&emsp13;<img
                            src="@/assets/engineering-text.svg"
                            alt="Engineering"
                            class="h-[0.7rem] inline align-baseline"
                        />&emsp13;Management&emsp13;System
                    </span>
                </h1>
            </router-link>
        </div>
        <div
            class="flex flex-col lg:fixed lg:h-full w-[inherit] border-b-4 lg:border-b-0 lg:border-r-4 border-primary py-2 transition-all ease-in-out"
            :class="{
                [navBackgroundClass]: true,
                'lg:w-64': isExpanded,
                'lg:w-20': isCollapsed,
                hidden: collapsedHamburger,
            }"
        >
            <router-link class="block !no-underline mx-2 mb-2" :to="{ name: 'welcome' }">
                <h1
                    class="text-center grow overflow-hidden leading-loose tracking-widest whitespace-nowrap hidden h-20 lg:flex lg:items-center lg:justify-center"
                    v-tooltip.right="isCollapsed ? 'Shelby Engineering Management System' : undefined"
                >
                    <template v-if="isCollapsed">
                        <span class="small-caps text-sm"> SEMS </span>
                    </template>
                    <template v-else>
                        <span class="text-sm">
                            <!-- the space matters -->
                            <img
                                src="@/assets/shelby-text.svg"
                                alt="Shelby"
                                class="h-[0.68rem] inline align-baseline"
                            />&emsp13;<img
                                src="@/assets/engineering-text.svg"
                                alt="Engineering"
                                class="h-[0.68rem] inline align-baseline"
                            /><br />
                            Management&emsp13;System
                        </span>
                    </template>
                </h1>
            </router-link>
            <div
                class="flex lg:flex-1 flex-row lg:flex-col flex-wrap lg:flex-nowrap max-w-full xs:max-lg:max-h-[80vh] overflow-y-auto relative py-2 gap-1"
                :class="{
                    'px-2': isExpanded,
                }"
            >
                <div
                    v-for="(item, index) of navigationItems"
                    :class="{
                        'mb-4': index < navigationItems.length - 1,
                    }"
                    class="w-full flex lg:flex-col flex-wrap"
                >
                    <!-- header -->
                    <Divider v-if="item.label" class="!mt-0 tracking-wide" align="left">
                        <link-icon
                            v-if="item.items && !greaterOrEqualLg"
                            class="whitespace-nowrap"
                            :class="{ '!justify-start': isExpanded, 'm-auto': isCollapsed }"
                            :fluid="greaterOrEqualLg"
                            :verb="menuCategoryDisclosureStatus[item.key] ? 'collapseUp' : 'collapseDown'"
                            size="small"
                            @click="toggleDisclosure(item.key)"
                            :label="showLabels ? item.label : undefined"
                            severity="secondary"
                        />
                        <template v-else>
                            {{ showLabels ? item.label : undefined }}
                        </template>
                    </Divider>
                    <!-- sub items -->
                    <template
                        v-if="greaterOrEqualLg || menuCategoryDisclosureStatus[item.key] || !item.label"
                        v-for="innerItem of item.items"
                    >
                        <link-icon
                            :href="innerItem.isNewSite ? undefined : LEGACY_HOSTNAME + innerItem.url"
                            class="whitespace-nowrap no-underline"
                            :class="{ '!justify-start': isExpanded, 'm-auto': isCollapsed }"
                            :fluid="greaterOrEqualLg"
                            label-class="text-left font-normal"
                            v-tooltip.right="showTooltip ? innerItem.label : undefined"
                            target="_blank"
                            rel="noopener"
                            :label="showLabels ? innerItem.label : undefined"
                            size="small"
                            text
                            label-align="left"
                            :app="innerItem.app"
                            :model="innerItem.model"
                            :icon="innerItem.icon"
                            :verb="innerItem.verb"
                            :to="innerItem.to"
                            :view="innerItem.view"
                            v-bind="
                                innerItem.isNewSite ?
                                    {
                                        onClick: innerItem.command,
                                    }
                                :   undefined
                            "
                            severity="secondary"
                            :skip-model-lookup="!innerItem.isNewSite"
                        />
                    </template>
                </div>
            </div>
            <div class="hidden lg:flex mx-2 my-2">
                <button-icon
                    class="m-auto"
                    fluid
                    :verb="isCollapsed ? 'collapseRight' : 'collapseLeft'"
                    :label="isCollapsed ? undefined : 'Collapse'"
                    size="small"
                    @click="collapseNavStore.toggle"
                />
            </div>
            <div class="flex flex-row lg:flex-col mx-2 gap-2 mb-2 flex-wrap lg:flex-nowrap">
                <!-- todo: tooltip? popover? -->
                <button-icon
                    :fluid="greaterOrEqualLg"
                    class="grow m-auto"
                    verb="showProfile"
                    :label="showLabels ? 'User Profile' : undefined"
                    size="small"
                    severity="secondary"
                />
                <router-link v-slot="{ navigate }" custom :to="{ name: 'sign-out' }">
                    <button-icon
                        :fluid="greaterOrEqualLg"
                        class="grow m-auto"
                        verb="signOut"
                        :label="showLabels ? 'Sign Out' : undefined"
                        size="small"
                        @click="navigate"
                        severity="secondary"
                    />
                </router-link>
                <button-icon
                    :fluid="greaterOrEqualLg"
                    class="grow m-auto"
                    :verb="darkModeStore.isDark ? 'toggleDarkModeDark' : 'toggleDarkModeLight'"
                    :label="
                        showLabels ?
                            darkModeStore.isDark ?
                                'Dark Mode'
                            :   'Light Mode'
                        :   undefined
                    "
                    size="small"
                    @click="darkModeStore.toggle()"
                    severity="contrast"
                />
            </div>
            <div v-if="false" class="flex flex-col lg:w-full px-2 lg:px-4 text-sm">
                <div class="flex flex-row justify-between min-w-0">
                    <span>User:</span><code>{{ loggedInUser?.name }}</code>
                </div>
                <div class="flex flex-row justify-between">
                    <span>User Name:</span><code>{{ loggedInUser?.username }}</code>
                </div>
                <div class="flex flex-row justify-between">
                    <span>Group(s):</span><code v-if="loggedInUser?.is_superuser" class="text-red-500">superuser</code
                    ><code v-else>{{ (loggedInUser?.groups || []).join(", ") }}</code>
                </div>
                <div class="flex flex-row justify-between">
                    <span>Client Version:</span><code>{{ version.myVersion }}</code>
                </div>
                <div class="flex flex-row justify-between">
                    <span>Server Version:</span><code>{{ version.serverVersion }}</code>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped></style>
