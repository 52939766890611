<script setup>
import { useLinkModelView } from "@vueda/use/useLinkModelView.js";
import Button from "primevue/button";
import { useRouter } from "vue-router";

import FontAwesomeIconDisplay from "@/components/FontAwesomeIconDisplay.vue";
import { useIconMap } from "@/use/useIconMap.js";

const props = defineProps({
    icon: {
        type: [String, Array],
        default: undefined,
    },
    verb: {
        type: String,
        default: undefined,
    },
    title: {
        type: String,
        default: undefined,
    },
    app: {
        type: String,
        default: undefined,
    },
    model: {
        type: String,
        default: undefined,
    },
    pk: {
        type: [String, Number, Array],
        default: undefined,
    },
    view: {
        type: [String, Array, Object],
        default: undefined,
    },
    to: {
        type: [String, Object],
        default: undefined,
    },
    onClick: {
        type: Function,
        default: undefined,
    },
    text: {
        type: Boolean,
        default: false,
    },
});
const router = useRouter();
const autoIcon = useIconMap(props);
const linkModelView = useLinkModelView(props);
const handleClick = (e) => {
    if (props.onClick) {
        e.preventDefault();
        props.onClick();
    } else if (props.to) {
        e.preventDefault();
        router.push(props.to);
    } else if (linkModelView.href.value && !linkModelView.actionDisabled.value) {
        e.preventDefault();
        linkModelView.navigate();
    }
    // let the click event propagate
};
</script>

<template>
    <Button v-tooltip="title" @click="handleClick" v-bind="{ text }">
        <template v-if="autoIcon" #icon="slotProps">
            <span :class="slotProps.class">
                <font-awesome-icon-display :icon="autoIcon" />
            </span>
        </template>
    </Button>
</template>
