import vuedaTailwind from "@vueda/theme/vueda-tailwind/index.js";
import { patchTheme } from "@vueda/use/useTheme.js";
import cloneDeep from "lodash-es/cloneDeep.js";

export function setupVuedaTheme() {
    const pt = {};
    for (const componentName of ["FieldSetTabularInline", "FieldSetStackedInline"]) {
        pt[componentName] = {
            ...cloneDeep(vuedaTailwind[componentName]),
            title: {
                class: [...vuedaTailwind[componentName].title.class, "text-xl"],
            },
        };
    }
    pt["PageTitle"] = {
        ...cloneDeep(vuedaTailwind["PageTitle"]),
        container: {
            class: [...vuedaTailwind["PageTitle"].container.class, "bg-surface-100 dark:bg-surface-800"],
        },
        gradient: {
            class: [
                ...vuedaTailwind["PageTitle"].gradient.class,
                "bg-gradient-to-b from-surface-100 to-transparent dark:from-surface-800 dark:to-transparent",
            ],
        },
    };
    patchTheme(pt);
}
