import tailwindConfig from "../tailwind.config.js";
import { definePreset } from "@primevue/themes";
import Lara from "@primevue/themes/lara";
import { warningsPrimeVueConfig } from "@vueda/theme/warnings.js";
import resolveConfig from "tailwindcss/resolveConfig.js";

const fullConfig = resolveConfig(tailwindConfig);
const scale = ["50", "100", "200", "300", "400", "500", "600", "700", "800", "900", "950"];
const tailwindColorScale = (colorName, extra) => {
    const result = {
        ...extra,
    };
    if (!(colorName in fullConfig.theme.colors)) {
        throw new Error(`Color ${colorName} not found in tailwind config`);
    }
    for (const s of scale) {
        result[s] = fullConfig.theme.colors[colorName][s];
    }
    return result;
};

const CustomizedLara = definePreset(Lara, {
    primitive: {
        blue: tailwindColorScale("azure-radiance"),
        green: tailwindColorScale("emerald"),
        red: tailwindColorScale("crimson"),
        purple: tailwindColorScale("fuchsia-blue"),
        yellow: tailwindColorScale("amber"), // message warn...
        amber: tailwindColorScale("amber"),
        orange: tailwindColorScale("amber"), // button warn...
    },
    semantic: {
        extend: {
            primary: tailwindColorScale("christi"),
            colorScheme: {
                light: {
                    ...warningsPrimeVueConfig.semantic.extend.colorScheme.light,
                },
                dark: {
                    ...warningsPrimeVueConfig.semantic.extend.colorScheme.dark,
                },
            },
        },
    },
    components: {
        ...warningsPrimeVueConfig.components,
        paginator: {
            background: "transparent",
        },
        message: {
            light: {
                help: {
                    background: "color-mix(in srgb, {purple.50}, transparent 5%)",
                    borderColor: "transparent",
                    color: "{purple.600}",
                    shadow: "none",
                    closeButton: {
                        hoverBackground: "{purple.100}",
                        focusRing: {
                            color: "{focus.ring.color}",
                            shadow: "0 0 0 0.2rem {purple.200}",
                        },
                    },
                    outlined: {
                        color: "{purple.600}",
                        borderColor: "{purple.600}",
                    },
                    simple: {
                        color: "{purple.600}",
                    },
                },
            },
            dark: {
                help: {
                    background: "color-mix(in srgb, {purple.500}, transparent 84%)",
                    borderColor: "transparent",
                    color: "{purple.500}",
                    shadow: "none",
                    closeButton: {
                        hoverBackground: "rgba(255, 255, 255, 0.05)",
                        focusRing: {
                            color: "{focus.ring.color}",
                            shadow: "0 0 0 0.2rem color-mix(in srgb, {purple.500}, transparent 80%)",
                        },
                    },
                    outlined: {
                        color: "{purple.500}",
                        borderColor: "{purple.500}",
                    },
                    simple: {
                        color: "{purple.500}",
                    },
                },
            },
            css: ({ dt }) =>
                `
.p-message-help {
    background: ${dt("message.help.background")};
    outline-color: ${dt("message.help.border.color")};
    color: ${dt("message.help.color")};
    box-shadow: ${dt("message.help.shadow")};
}

.p-message-help.p-message-outlined {
    background: transparent;
    color: ${dt("message.help.outlined.color")};
    outline-color: ${dt("message.help.outlined.borderColor")};
}

.p-message-help.p-message-simple {
    background: transparent;
    color: ${dt("message.help.simple.color")};
}
`,
        },
    },
});

export default CustomizedLara;
