// custom tailwind-based breakpoints
export const breakpointsVueda = {
    "2xs": 480, // custom, see tailwind.config.js#theme[.extend].screens
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536,
    "3xl": 1920, // custom, see tailwind.config.js#theme[.extend].screens
    inf: 999999, // custom, see tailwind.config.js#theme[.extend].screens
};
